import { combineReducers, createStore, Store } from 'redux';
import booking from 'booking/redux/reducers';
import menu from './menu/reducers';
import adBar from './adBar/reducers';
import consultation from '../../surgery/redux/reducers';
import quoteModal from '../../surgery/redux/quote/reducers';
import referralModal from '../../surgery/redux/referral/reducers';
import firstVisit from '../../firstVisit/redux/reducers';
import telehealthBooking from '../../telehealth/redux/reducers';

const rootReducer = combineReducers({
    booking,
    menu,
    adBar,
    consultation,
    quoteModal,
    referralModal,
    firstVisit,
    telehealthBooking,
});

const initialState = {};

export default function createReduxStore(): Store {
    return createStore(
        rootReducer,
        initialState,
        typeof window !== 'undefined' && window?.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : undefined,
    );
}
