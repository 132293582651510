export const TOGGLE_MOBILE_MENU = 'menu/TOGGLE_MOBILE_MENU';
export const OPEN_MOBILE_MENU = 'menu/OPEN_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'menu/CLOSE_MOBILE_MENU';

export type ToggleMobileMenuAction = {
    type: typeof TOGGLE_MOBILE_MENU;
};

export type OpenMobileMenuAction = {
    type: typeof OPEN_MOBILE_MENU;
};

export type CloseMobileMenuAction = {
    type: typeof CLOSE_MOBILE_MENU;
};

export type MobileMenuActionTypes =
    | ToggleMobileMenuAction
    | OpenMobileMenuAction
    | CloseMobileMenuAction;

export type MobileMenuState = {
    open: boolean;
};
