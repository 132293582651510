export const TOGGLE_CONSULTATION = 'surgery/TOGGLE_CONSULTATION';

export const OPEN_CONSULTATION = 'surgery/OPEN_CONSULTATION';
export const CLOSE_CONSULTATION = 'surgery/CLOSE_CONSULTATION';

export const SELECT_LOCATION = 'surgery/SELECT_LOCATION';

export const SET_STEP = 'surgery/SET_STEP';
export const NEXT = 'surgery/NEXT';
export const BACK = 'surgery/BACK';

export const FINISHED = 'surgery/FINISHED';
export const FAILED = 'surgery/FAILED';

export type ConsultationStep = 'location' | 'date';

export const CONSULTATION_STEPS: ReadonlyArray<ConsultationStep> = [
    'location',
    'date',
];

export type ToggleConsultationAction = {
    type: typeof TOGGLE_CONSULTATION;
};

export type OpenConsultationAction = {
    type: typeof OPEN_CONSULTATION;
};

export type CloseConsultationAction = {
    type: typeof CLOSE_CONSULTATION;
};

export type SelectLocationAction = {
    type: typeof SELECT_LOCATION;
    payload: {
        locationId: string;
    };
};

export type SetStepAction = {
    type: typeof SET_STEP;
    payload: {
        step: ConsultationStep;
    };
};

export type NextAction = {
    type: typeof NEXT;
};

export type BackAction = {
    type: typeof BACK;
};

export type FailedAction = {
    type: typeof FAILED;
    payload: {
        error: string;
    };
};

export type ConsultationFormActionTypes =
    | SelectLocationAction
    | SetStepAction
    | NextAction
    | BackAction;

export type ConsultationActionTypes =
    | ToggleConsultationAction
    | OpenConsultationAction
    | CloseConsultationAction
    | ConsultationFormActionTypes;

export type ConsultationState = {
    open: boolean;
    step: ConsultationStep;
    locationId: string;
    error: string | null;
};
