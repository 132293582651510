import * as React from 'react';
import Loading from '../components/Loading';

export type LoadFn<Props> = () => Promise<{
    default: React.ComponentType<Props>;
}>;

export default function loadable<Props>(
    factory: LoadFn<Props>,
): React.FunctionComponent<Props> {
    const LazyComponent = React.lazy<React.ComponentType<Props>>(factory);

    return function WrapperComponent(props: Props) {
        return (
            <React.Suspense fallback={<Loading />}>
                <LazyComponent {...props} />
            </React.Suspense>
        );
    };
}
