import * as React from 'react';
import type { Maybe, NextdoorSettings } from '@types/gatsby-types';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query NextdoorPixelQuery {
        settings {
            nextdoor {
                enabled
                pixelId
            }
        }
    }
`;

type NextdoorPixelQuery = {
    settings: Maybe<{
        nextdoor: Maybe<NextdoorSettings>;
    }>;
};

export default function NextdoorPixel() {
    const { settings } = useStaticQuery<NextdoorPixelQuery>(query);

    const pixelId = settings?.nextdoor?.pixelId;
    const enabled = settings?.nextdoor?.enabled;

    if (enabled && pixelId) {
        return (
            <script
                type="text/partytown"
                key="nextdoor"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(win, doc, sdk_url) {
                       if(win.ndp) return;
                        var tr = win.ndp = function() {
                            tr.handleRequest ? 
                                tr.handleRequest.apply(tr, arguments)
                                : tr.queue.push(arguments);
                        };
                        tr.queue = [];
                        var s = 'script';
                        var new_script_section = doc.createElement(s);
                        new_script_section.async = true;
                        new_script_section.src = sdk_url;
                        var insert_pos = doc.getElementsByTagName(s)[0];
                        insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
                    })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
                    
                    ndp('init', '${pixelId}', {})
                    ndp('track','PAGE_VIEW');
      `,
                }}
            />
        );
    }

    return null;
}
