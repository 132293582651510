import {
    CLOSE_REFERRAL_MODAL,
    ReferralModalActionTypes,
    ReferralModalState,
    OPEN_REFERRAL_MODAL,
    TOGGLE_REFERRAL_MODAL,
} from './types';

const initialState: ReferralModalState = {
    open: false,
};

export default function menuReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: ReferralModalState = initialState,
    action: ReferralModalActionTypes,
): ReferralModalState {
    switch (action.type) {
        case TOGGLE_REFERRAL_MODAL:
            return {
                ...state,
                open: !state.open,
            };
        case OPEN_REFERRAL_MODAL: {
            return {
                ...state,
                open: true,
            };
        }
        case CLOSE_REFERRAL_MODAL: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
}
