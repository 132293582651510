export const OPEN_FIRST_VISIT = 'firstVisit/OPEN_FIRST_VISIT';
export const CLOSE_FIRST_VISIT = 'firstVisit/CLOSE_FIRST_VISIT';

export const ENTRY = 'firstVisit/ENTRY';

export const SET_STEP = 'firstVisit/SET_STEP';
export const NEXT = 'firstVisit/NEXT';
export const BACK = 'firstVisit/BACK';

export const FINISHED = 'firstVisit/FINISHED';
export const FAILED = 'firstVisit/FAILED';

export type FirstVisitStep = 'entry' | 'date';

export const FIRST_VISIT_STEPS: ReadonlyArray<FirstVisitStep> = [
    'entry',
    'date',
];

export type OpenFirstVisitAction = {
    type: typeof OPEN_FIRST_VISIT;
};

export type CloseFirstVisitAction = {
    type: typeof CLOSE_FIRST_VISIT;
};

export type SetStepAction = {
    type: typeof SET_STEP;
    payload: {
        step: FirstVisitStep;
    };
};

export type NextAction = {
    type: typeof NEXT;
};

export type BackAction = {
    type: typeof BACK;
};

export type FailedAction = {
    type: typeof FAILED;
    payload: {
        error: string;
    };
};

export type FirstVisitFormActionTypes = SetStepAction | NextAction | BackAction;

export type FirstVisitActionTypes =
    | OpenFirstVisitAction
    | CloseFirstVisitAction
    | FirstVisitFormActionTypes;

export type FirstVisitState = {
    open: boolean;
    step: FirstVisitStep;
    error: string | null;
};
