import * as React from 'react';
import { ScriptStrategy } from 'gatsby';
import renderScript from '../lib/renderScript';

type ScriptProps = {
    src?: string;
    content?: string;
    timeoutMs?: number;
    strategy: Exclude<ScriptStrategy, ScriptStrategy.offMainThread>;
    attributes?: Record<string, string | undefined>;
    id?: string;
};

export default function Script({
    strategy,
    src,
    content,
    timeoutMs = 0,
    attributes,
    id,
}: ScriptProps) {
    React.useEffect(() => {
        return renderScript(strategy, timeoutMs, src, content, attributes, id);
    }, [src, content, timeoutMs, strategy, attributes, id]);

    return null;
}
