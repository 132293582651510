export const TOGGLE_QUOTE_MODAL = 'quoteModal/TOGGLE_QUOTE_MODAL';
export const OPEN_QUOTE_MODAL = 'quoteModal/OPEN_QUOTE_MODAL';
export const CLOSE_QUOTE_MODAL = 'quoteModal/CLOSE_QUOTE_MODAL';

export type ToggleQuoteModalAction = {
    type: typeof TOGGLE_QUOTE_MODAL;
};

export type OpenQuoteModalAction = {
    type: typeof OPEN_QUOTE_MODAL;
};

export type CloseQuoteModalAction = {
    type: typeof CLOSE_QUOTE_MODAL;
};

export type QuoteModalActionTypes =
    | ToggleQuoteModalAction
    | OpenQuoteModalAction
    | CloseQuoteModalAction;

export type QuoteModalState = {
    open: boolean;
};
