export const TOGGLE_REFERRAL_MODAL = 'referralModal/TOGGLE_REFERRAL_MODAL';
export const OPEN_REFERRAL_MODAL = 'referralModal/OPEN_REFERRAL_MODAL';
export const CLOSE_REFERRAL_MODAL = 'referralModal/CLOSE_REFERRAL_MODAL';

export type ToggleReferralModalAction = {
    type: typeof TOGGLE_REFERRAL_MODAL;
};

export type OpenReferralModalAction = {
    type: typeof OPEN_REFERRAL_MODAL;
};

export type CloseReferralModalAction = {
    type: typeof CLOSE_REFERRAL_MODAL;
};

export type ReferralModalActionTypes =
    | ToggleReferralModalAction
    | OpenReferralModalAction
    | CloseReferralModalAction;

export type ReferralModalState = {
    open: boolean;
};
