import {
    CLOSE_MOBILE_MENU,
    MobileMenuActionTypes,
    MobileMenuState,
    OPEN_MOBILE_MENU,
    TOGGLE_MOBILE_MENU,
} from './types';

const initialState: MobileMenuState = {
    open: false,
};

export default function menuReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: MobileMenuState = initialState,
    action: MobileMenuActionTypes,
): MobileMenuState {
    switch (action.type) {
        case TOGGLE_MOBILE_MENU:
            return {
                ...state,
                open: !state.open,
            };
        case OPEN_MOBILE_MENU: {
            return {
                ...state,
                open: true,
            };
        }
        case CLOSE_MOBILE_MENU: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
}
