import {
    TelehealthBookingActionTypes,
    TelehealthBookingState,
    CLOSE_TELEHEALTH_BOOKING,
    OPEN_TELEHEALTH_BOOKING,
} from './types';

const initialState: TelehealthBookingState = {
    open: false,
    error: null,
};

export default function telehealthBookingReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: TelehealthBookingState = initialState,
    { type }: TelehealthBookingActionTypes,
): TelehealthBookingState {
    switch (type) {
        case OPEN_TELEHEALTH_BOOKING:
            return {
                ...state,
                open: true,
            };
        case CLOSE_TELEHEALTH_BOOKING: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
}
