export const OPEN_TELEHEALTH_BOOKING = 'telehealth/OPEN_TELEHEALTH_BOOKING';
export const CLOSE_TELEHEALTH_BOOKING = 'telehealth/CLOSE_TELEHEALTH_BOOKING';

export const ENTRY = 'telehealth/ENTRY';

export const FINISHED = 'telehealth/FINISHED';
export const FAILED = 'telehealth/FAILED';

export type OpenTelehealthBookingAction = {
    type: typeof OPEN_TELEHEALTH_BOOKING;
};

export type CloseTelehealthBookingAction = {
    type: typeof CLOSE_TELEHEALTH_BOOKING;
};

export type FailedAction = {
    type: typeof FAILED;
    payload: {
        error: string;
    };
};

export type TelehealthBookingActionTypes =
    | OpenTelehealthBookingAction
    | CloseTelehealthBookingAction;

export type TelehealthBookingState = {
    open: boolean;
    error: string | null;
};
