import type { Maybe, BingSettings } from '@types/gatsby-types';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query BingPixelQuery {
        settings {
            bing {
                enabled
                pixelId
            }
        }
    }
`;

type BingPixelQuery = {
    settings: Maybe<BingSettings>;
};

type UseBingPixelId = {
    enabled: boolean;
    pixelId: string | null;
};

export default function useBingPixelId(): UseBingPixelId {
    const { settings } = useStaticQuery<BingPixelQuery>(query);

    return {
        enabled: !!settings?.bing?.enabled,
        pixelId: settings?.bing?.pixelId ?? null,
    };
}
