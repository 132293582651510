import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import classnames from 'classnames';
import * as styles from './Loading.module.scss';

export interface LoadingProps extends CircularProgressProps {
    label?: string;
    classes?: {
        root?: string;
    };
}

function Loading({ label, ...props }: LoadingProps) {
    return (
        <div className={classnames(props.classes?.root, styles.root)}>
            <CircularProgress
                aria-label={label ?? 'loading details'}
                {...props}
            />
        </div>
    );
}

export default Loading;
