import type { RouteUpdateArgs } from 'gatsby';
import 'normalize.css';
import 'styles/scss/global.scss';
import { VOGLIO_FIELDS } from '@bondvet/types/voglio';

export { wrapPageElement, wrapRootElement } from './gatsby-shared';

export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
    const params = new URLSearchParams(location.search);

    // save voglio params to local storage as individual keys
    VOGLIO_FIELDS.forEach((key) => {
        const value = params.get(key);
        if (value) {
            // if we already have something in localStorage, don't overwrite it
            // we want to keep the original value in localStorage
            if (!localStorage.getItem(key)) {
                localStorage.setItem(key, value);
            }
            sessionStorage.setItem(key, value);
        }
    });

    if (window) {
        window.zESettings = {
            webWidget: {
                color: {
                    launcher: '#103559',
                    header: '#10365A',
                    resultLists: '#295889',
                    button: '#103559',
                    articleLinks: '#FF4D4D',
                },
            },
        };
    }

    if (location.pathname.includes('/booking')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.zE && window.zE('webWidget', 'hide');
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.zE && window.zE('webWidget', 'show');
    }

    return null;
};

export function onClientEntry() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('modernizr').then();
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!('IntersectionObserver' in window)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        import('intersection-observer').then();
    }
}

// automatically reload when the service worker has been updated
export const onServiceWorkerUpdateReady = () => {
    window.location.reload();
};
