import * as React from 'react';
import type { Maybe, RedditSettings } from '@types/gatsby-types';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query RedditPixelQuery {
        settings {
            reddit {
                enabled
                pixelId
            }
        }
    }
`;

type RedditPixelQuery = {
    settings: Maybe<{
        reddit: Maybe<RedditSettings>;
    }>;
};

export default function RedditPixel() {
    const { settings } = useStaticQuery<RedditPixelQuery>(query);

    const pixelId = settings?.reddit?.pixelId;
    const enabled = settings?.reddit?.enabled;

    if (enabled && pixelId) {
        return (
            <script
                type="text/partytown"
                dangerouslySetInnerHTML={{
                    __html: `
                    !function(w, d) {
                        if (!w.rdt) {
                            var p = w.rdt = function() {
                                p.sendEvent ?
                                    p.sendEvent.apply (p,arguments)
                                    : p.callQueue.push(arguments)
                            };
                            p.callQueue=[];
                            var t=d.createElement("script");
                            t.src="https://www.redditstatic.com/ads/pixel.js",
                            t.async=!0;
                            var s=d.getElementsByTagName("script")[0];
                            s.parentNode.insertBefore(t,s)
                        }
                    }(window, document);

                    rdt('init','${pixelId}');
                    rdt('track', 'PageVisit');
      `,
                }}
            />
        );
    }

    return null;
}
