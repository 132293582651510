export const TOGGLE_ADBAR = 'adBar/TOGGLE_ADBAR';
export const OPEN_ADBAR = 'adBar/OPEN_ADBAR';
export const CLOSE_ADBAR = 'adBar/CLOSE_ADBAR';

export type ToggleAdBarAction = {
    type: typeof TOGGLE_ADBAR;
};

export type OpenAdBarAction = {
    type: typeof OPEN_ADBAR;
};

export type CloseAdBarAction = {
    type: typeof CLOSE_ADBAR;
};

export type AdBarActionTypes =
    | ToggleAdBarAction
    | OpenAdBarAction
    | CloseAdBarAction;

export type AdBarState = {
    open: boolean;
};
