import * as React from 'react';
import type { ConvertSettings, Maybe } from '@types/gatsby-types';
import { graphql, ScriptStrategy, useStaticQuery } from 'gatsby';
import Script from 'analytics/components/Script';

const convertSettingsQuery = graphql`
    query ConvertSettingsQuery {
        settings {
            convert {
                enabled
                accountNumber
                projectNumber
            }
        }
    }
`;

type ConvertSettingsQuery = {
    settings: Maybe<{
        convert: Maybe<ConvertSettings>;
    }>;
};

export default function ConvertABScript() {
    const { settings } =
        useStaticQuery<ConvertSettingsQuery>(convertSettingsQuery);

    const enabled = !!settings?.convert?.enabled;
    const accountNumber = settings?.convert?.accountNumber;
    const projectNumber = settings?.convert?.projectNumber;

    if (enabled && !!accountNumber && !!projectNumber) {
        return (
            <Script
                id="convert"
                strategy={ScriptStrategy.postHydrate}
                src={`https://cdn-4.convertexperiments.com/js/${accountNumber}-${projectNumber}.js`}
            />
        );
    }

    return null;
}
