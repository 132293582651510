import {
    CLOSE_ADBAR,
    AdBarActionTypes,
    AdBarState,
    OPEN_ADBAR,
    TOGGLE_ADBAR,
} from './types';

const initialState: AdBarState = {
    open: true,
};

export default function menuReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: AdBarState = initialState,
    action: AdBarActionTypes,
): AdBarState {
    switch (action.type) {
        case TOGGLE_ADBAR:
            return {
                ...state,
                open: !state.open,
            };
        case OPEN_ADBAR: {
            return {
                ...state,
                open: true,
            };
        }
        case CLOSE_ADBAR: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
}
