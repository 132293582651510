import {
    CLOSE_QUOTE_MODAL,
    QuoteModalActionTypes,
    QuoteModalState,
    OPEN_QUOTE_MODAL,
    TOGGLE_QUOTE_MODAL,
} from './types';

const initialState: QuoteModalState = {
    open: false,
};

export default function menuReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: QuoteModalState = initialState,
    action: QuoteModalActionTypes,
): QuoteModalState {
    switch (action.type) {
        case TOGGLE_QUOTE_MODAL:
            return {
                ...state,
                open: !state.open,
            };
        case OPEN_QUOTE_MODAL: {
            return {
                ...state,
                open: true,
            };
        }
        case CLOSE_QUOTE_MODAL: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
}
