import {
    BACK,
    CONSULTATION_STEPS,
    ConsultationActionTypes,
    ConsultationState,
    CLOSE_CONSULTATION,
    NEXT,
    OPEN_CONSULTATION,
    SELECT_LOCATION,
    SelectLocationAction,
    SET_STEP,
    SetStepAction,
    TOGGLE_CONSULTATION,
} from './types';

const initialState: ConsultationState = {
    open: false,
    step: 'location',
    locationId: '',
    error: null,
};

export default function bookingReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: ConsultationState = initialState,
    { type, ...action }: ConsultationActionTypes,
): ConsultationState {
    switch (type) {
        case TOGGLE_CONSULTATION:
            if (state.open) {
                return {
                    ...initialState,
                };
            }

            return {
                ...state,
                open: !state.open,
            };
        case OPEN_CONSULTATION:
            return {
                ...state,
                open: true,
            };
        case CLOSE_CONSULTATION: {
            return {
                ...initialState,
            };
        }

        case SELECT_LOCATION:
            return {
                ...state,
                locationId: (action as SelectLocationAction).payload.locationId,
            };
        case SET_STEP:
            return {
                ...state,
                step: (action as SetStepAction).payload.step,
            };
        case NEXT: {
            const idx = CONSULTATION_STEPS.indexOf(state.step);

            return {
                ...state,
                step: CONSULTATION_STEPS[
                    Math.min(CONSULTATION_STEPS.length - 1, idx + 1)
                ],
            };
        }
        case BACK: {
            const idx = CONSULTATION_STEPS.indexOf(state.step);

            return {
                ...state,
                step: CONSULTATION_STEPS[Math.max(0, idx - 1)],
            };
        }
        default:
            return state;
    }
}
