import {
    BACK,
    FIRST_VISIT_STEPS,
    FirstVisitActionTypes,
    FirstVisitState,
    CLOSE_FIRST_VISIT,
    NEXT,
    OPEN_FIRST_VISIT,
    SET_STEP,
    SetStepAction,
} from './types';

const initialState: FirstVisitState = {
    open: false,
    step: 'entry',
    error: null,
};

export default function firstVisitReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: FirstVisitState = initialState,
    { type, ...action }: FirstVisitActionTypes,
): FirstVisitState {
    switch (type) {
        case OPEN_FIRST_VISIT:
            return {
                ...state,
                open: true,
            };
        case CLOSE_FIRST_VISIT: {
            return {
                ...initialState,
            };
        }

        case SET_STEP:
            return {
                ...state,
                step: (action as SetStepAction).payload.step,
            };
        case NEXT: {
            const idx = FIRST_VISIT_STEPS.indexOf(state.step);

            return {
                ...state,
                step: FIRST_VISIT_STEPS[
                    Math.min(FIRST_VISIT_STEPS.length - 1, idx + 1)
                ],
            };
        }
        case BACK: {
            const idx = FIRST_VISIT_STEPS.indexOf(state.step);

            return {
                ...state,
                step: FIRST_VISIT_STEPS[Math.max(0, idx - 1)],
            };
        }
        default:
            return state;
    }
}
