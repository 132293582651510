import { renderToStaticMarkup } from 'react-dom/server';
import translation from './translations';

function combineTranslations(
    part: Record<string, string | Record<string, string | unknown>>,
    path: ReadonlyArray<string> = [],
): Record<string, string> {
    return Object.keys(part).reduce<Record<string, string>>(
        (result: Record<string, string>, key: string) => {
            const value = part[key];

            if (typeof value === 'string') {
                return {
                    ...result,
                    [[...path, key].join('.')]: value,
                };
            }

            return {
                ...result,
                ...combineTranslations(
                    value as Record<
                        string,
                        string | Record<string, string | unknown>
                    >,
                    [...path, key],
                ),
            };
        },
        {},
    );
}

export const messages = combineTranslations(translation);

export default {
    languages: [{ name: 'English', code: 'en' }],
    translation,
    options: {
        defaultLanguage: 'en',
        renderToStaticMarkup,
    },
};
