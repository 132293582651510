import * as React from 'react';
import type { AdaChatBotSettings } from '@types/gatsby-types';
import { useLocation } from '@reach/router';

// To simply auto-open the chat window, use https://bondvet.com?chat
// To trigger a campaign, use https://bondvet.com?chat&campaignName=CAMPAIGN_NAME
// For the "Rescheduling_Nurse_Visits" campaign, you should also pass &email=janedo@gmail.com
export default function useAdaChatbotControls(
    enabled: boolean,
    settings: AdaChatBotSettings,
): void {
    // make sure that a missing `nurseVisitRescheduleCampaignName` setting
    // doesn't mess up our `window.adaEmbed.triggerCampaign` call later
    const nurseVisitRescheduleCampaignName =
        settings.nurseVisitRescheduleCampaignName ?? '';

    const location = useLocation();
    const initialized = React.useRef(false);

    const params = React.useMemo(() => {
        return new URLSearchParams(location?.search);
    }, [location]);

    const shouldOpenAutomatically = enabled && params.get('chat') !== null;
    const campaignName = params.get('campaignName');
    const email = params.get('email') || '';

    const adaReadyCallback = React.useCallback(() => {
        if (!window.adaEmbed || initialized.current) {
            return;
        }

        initialized.current = true;

        if (shouldOpenAutomatically) {
            // this currently isn't used by any other ada campaigns
            // other than nurse visit reschedule, but with this
            // in place we can simply use it in other workflows
            // as well.
            window.adaEmbed
                .setMetaFields({
                    urlEmail: email,
                })
                .then();

            // If a specific campaign is passed, trigger it
            // Otherwise, simply force the chat to open
            switch (campaignName) {
                case nurseVisitRescheduleCampaignName:
                    window.adaEmbed
                        .triggerCampaign(nurseVisitRescheduleCampaignName)
                        .then();
                    break;
                case null:
                default:
                    window.adaEmbed.toggle().then();
            }
        }
    }, [
        campaignName,
        email,
        nurseVisitRescheduleCampaignName,
        shouldOpenAutomatically,
    ]);

    React.useEffect(() => {
        window.adaSettings = window.adaSettings || { handle: '' };

        window.adaSettings.adaReadyCallback = adaReadyCallback;

        window.requestAnimationFrame(() => {
            if (window?.adaEmbed?.toggle) {
                // we missed it
                adaReadyCallback();
            }
        });
    }, [adaReadyCallback]);
}
